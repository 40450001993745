"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const asserts_1 = require("external/dev_april_corgi~/js/common/asserts");
const math_1 = require("external/dev_april_corgi~/js/common/math");
const corgi = __importStar(require("external/dev_april_corgi~/js/corgi"));
const events_1 = require("external/dev_april_corgi~/js/emu/events");
const input_1 = require("external/dev_april_corgi~/js/emu/input");
const select_1 = require("external/dev_april_corgi~/js/emu/select");
const formatters_1 = require("java/org/trailcatalog/client/common/formatters");
const SimpleS2_1 = require("java/org/trailcatalog/s2/SimpleS2");
const events_2 = require("js/map/events");
const map_element_1 = require("js/map/map_element");
const viewer_controller_1 = require("./viewer_controller");
require("./app.css");
function App({}, state, updateState) {
    if (!state) {
        state = {
            cellInput: '',
            cellType: 's2',
            level: viewer_controller_1.ZOOM_LEVEL,
        };
    }
    const parameters = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    const llz = {
        lat: (0, math_1.floatCoalesce)(parameters.lat, 46.859369),
        lng: (0, math_1.floatCoalesce)(parameters.lng, -121.747888),
        zoom: (0, math_1.floatCoalesce)(parameters.zoom, 12),
    };
    let deepestZoom;
    if (state.cellType === 's2') {
        deepestZoom = viewer_controller_1.MAX_S2_ZOOM;
    }
    else if (state.cellType === 'z/x/y') {
        deepestZoom = viewer_controller_1.MAX_ZXY_ZOOM;
    }
    else {
        (0, asserts_1.checkExhaustive)(state.cellType);
    }
    return corgi.createVirtualElement("div", { className: "h-full" },
        corgi.createVirtualElement("div", { js: corgi.bind({
                controller: viewer_controller_1.ViewerController,
                events: {
                    corgi: [[events_2.MAP_MOVED, 'onMove']],
                    render: 'wakeup',
                },
                state: [state, updateState],
            }), className: "h-full" },
            corgi.createVirtualElement(map_element_1.MapElement, { camera: llz, ref: "map" }),
            corgi.createVirtualElement("div", { className: "absolute flex gap-2 h-6 top-4 right-4" },
                corgi.createVirtualElement(select_1.Select, { className: "bg-white text-xs", unboundEvents: { corgi: [[events_1.CHANGED, 'setCellType']] }, options: [
                        { label: 's2', value: 's2' },
                        { label: 'z/x/y', value: 'z/x/y' },
                    ] }),
                corgi.createVirtualElement(input_1.Input, { className: "bg-white text-xs", forceValue: true, unboundEvents: { corgi: [[events_1.CHANGED, 'showCells']] }, value: state.cellInput }),
                corgi.createVirtualElement(select_1.Select, { className: "bg-white text-xs", unboundEvents: { corgi: [[events_1.CHANGED, 'setLevel']] }, options: [
                        { label: 'zoom', value: String(viewer_controller_1.ZOOM_LEVEL) },
                        ...[...Array(deepestZoom).keys()].map(i => ({ label: String(i), value: String(i) })),
                    ] })),
            state.selectedS2
                ? corgi.createVirtualElement(CellPopupS2, { cell: state.selectedS2.cell, position: state.selectedS2.clickPx })
                : corgi.createVirtualElement(corgi.Fragment, null),
            state.selectedZxy
                ? corgi.createVirtualElement(CellPopupZxy, { llr: state.selectedZxy.llr, position: state.selectedZxy.clickPx, token: state.selectedZxy.token, xyz: state.selectedZxy.xyz })
                : corgi.createVirtualElement(corgi.Fragment, null)));
}
function CellPopupS2({ cell, position }) {
    const area = (0, formatters_1.formatArea)(SimpleS2_1.SimpleS2.cellIdToCell(cell).exactArea()
        * SimpleS2_1.SimpleS2.EARTH_RADIUS_METERS
        * SimpleS2_1.SimpleS2.EARTH_RADIUS_METERS);
    return corgi.createVirtualElement(corgi.Fragment, null,
        corgi.createVirtualElement("div", { className: "\n            absolute\n            bg-white\n            rounded\n            p-2\n            -translate-x-1/2\n            translate-y-[calc(-100%-0.75rem)]\n        ", style: `left: ${position[0]}px; top: ${position[1]}px` },
            corgi.createVirtualElement("div", { className: "font-bold" }, cell.toToken()),
            corgi.createVirtualElement("div", null,
                "level: ",
                cell.level()),
            corgi.createVirtualElement("div", null,
                "area: ",
                area.value,
                " ",
                area.unit),
            corgi.createVirtualElement("div", null,
                "id: ",
                cell.id().toString()),
            corgi.createVirtualElement("div", null,
                "min: ",
                cell.rangeMin().id().toString()),
            corgi.createVirtualElement("div", null,
                "max: ",
                cell.rangeMax().id().toString())));
}
function CellPopupZxy({ llr, position, token, xyz, }) {
    const areaRad = llr.area();
    const low = llr.lo();
    const high = llr.lo();
    const center = llr.getCenter();
    const area = (0, formatters_1.formatArea)(areaRad
        * SimpleS2_1.SimpleS2.EARTH_RADIUS_METERS
        * SimpleS2_1.SimpleS2.EARTH_RADIUS_METERS);
    return corgi.createVirtualElement(corgi.Fragment, null,
        corgi.createVirtualElement("div", { className: "\n            absolute\n            bg-white\n            rounded\n            p-2\n            -translate-x-1/2\n            translate-y-[calc(-100%-0.75rem)]\n        ", style: `left: ${position[0]}px; top: ${position[1]}px` },
            corgi.createVirtualElement("div", { className: "font-bold" }, token),
            corgi.createVirtualElement("div", null,
                "area: ",
                area.value,
                " ",
                area.unit),
            corgi.createVirtualElement("div", null,
                "center: ",
                center.latDegrees(),
                ",",
                center.lngDegrees()),
            corgi.createVirtualElement("div", null,
                "low: ",
                low.latDegrees(),
                ",",
                low.lngDegrees()),
            corgi.createVirtualElement("div", null,
                "high: ",
                low.latDegrees(),
                ",",
                high.lngDegrees()),
            corgi.createVirtualElement("div", null,
                "world size: ",
                Math.pow(2, xyz[2]))));
}
corgi.appendElement((0, asserts_1.checkExists)(document.getElementById('root')), corgi.createVirtualElement(App, null));
